import React from 'react'
import { ApolloLink, ApolloProvider as ApolloClientProvider } from '@apollo/client'

import { createClient } from './apollo.utils'

export interface ApolloProviderProps {
  link: ApolloLink
}

export default function ApolloProvider({ children, link }: React.PropsWithChildren<ApolloProviderProps>) {
  const client = React.useMemo(() => createClient(link), [])

  return (
    <ApolloClientProvider client={client}>
      {children}
    </ApolloClientProvider>
  )
}
