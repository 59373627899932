import { ApolloClient, ApolloLink,InMemoryCache } from '@apollo/client'

export const createClient = (apolloLink: ApolloLink) => {
  return new ApolloClient({
    link: apolloLink,
    cache: new InMemoryCache({
      addTypename: true
    }),
    defaultOptions: {
      mutate: {
        errorPolicy: 'all'
      }
    }
  })
}
