import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'

import RouteNotFoundRoute from './route-not-found'
import PaymentReceivedRoute from './route-payment-received'
import PublicDossierRoute from './route-public-dossier'

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        element={<RouteNotFoundRoute />}
        path={'/404'} />

      <Route
        element={<PaymentReceivedRoute />}
        path={'/payment-received'} />

      <Route
        element={<PublicDossierRoute />}
        path={'/:dossierNumber'} />

      <Route
        element={<Navigate to={'/404'} />}
        path={'*'} />
    </Routes>
  )
}
