import { Language } from '@straetus/react/interfaces'
import { addMessagesToCache, getDateMessages } from '@straetus/react/modules/language'

function fetchTranslations(languageCode: string) {
  return fetch(`https://cdn.simplelocalize.io/9a7827c0c21241ac999b9692c3b5f1f7/_latest/${languageCode.toLowerCase()}`)
    .then((response) => response.json())
    .catch(() => import('../translations/en.json'))
}

export async function loadMessages(language: Language) {
  const dateMessages = await getDateMessages(language)
  const messages = await fetchTranslations(language)

  addMessagesToCache(language, messages, dateMessages)

  return messages
}
