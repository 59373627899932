import { createHttpLink, from } from '@apollo/client'
import { createApolloErrorLink } from '@straetus/react/modules/graphql'

import { environment } from '../environments'

const apiLink = createHttpLink({
  uri: environment.graphql.api
})

export const apolloLink = from([createApolloErrorLink(), apiLink])
