import type { Environment } from '@straetus/react/modules/environment'

export const environment: Environment = {
  production: true,

  errorReportingApiKey: 'AIzaSyAR3ZmZ1EG4n2qoICw5R2NBXoctU-_6cJY',

  statusApi: 'https://functions.straetus.app/system-uptime?status=debtor-portal',
  statusDashboard: 'https://status.debtor-portal.straetus.app',

  docsUrl: 'https://straetus.help',
  debtorPortalUrl: 'https://pay.straetus.app',

  graphql: {
    api: 'https://apis.straetus.app/debtor-portal/graphql'
  }
}
